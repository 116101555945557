/* CSS styles for the dialog */
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.dialog-overlay.active {
    opacity: 1;
    visibility: visible;
}

.dialog-container {
    background-color: white;
    padding: clamp(2vw, 3.5rem, 5vw);
    border-radius: 8px;
    // width: 50%;
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    gap: clamp(1vw, 2.5rem, 4vw);
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog-close-btn {
    background: none;
    border: none;
    font-size: clamp(2vw, 4rem, 5vw);
    color: black;
    cursor: pointer;
}

.dialog-content {

    /* Add styles for the content inside the dialog */
    h5 {
        text-indent: 2vw;
        line-height: clamp(2vw, 3.5rem, 5vw);
    }

    a {
        text-decoration: none;
        color: black;
        font-weight: bold;
    }
}